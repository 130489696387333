<template>
    <div class="w3-theme-l1" style="">
        <div class="w3-container w3-margin-top" style="padding-bottom:10px">
            <Toolbar  
                :click-add="showAdd"
                :click-refresh="loadData"
                :click-print="showPrint"
                :status-print="false"
                :set-filter="setFilter"
                :field-find="filters.fields"
                :data   = "$store.getters['modLimaceria/recs']"
                :fields = "json_fields"
                type    = "xls"
                name    = "limaceria.xls"
                help = "aQSmjMLLvzc"
            />
            <div @mouseover="grid = true" @mouseleave="grid = false">
                <vue-scrolling-table v-bind:style="'height:'+  (screenHeight-149) +'px;'" :class="'w3-table w3-striped w3-white w3-small freezeFirstColumn freezeLastColumn'" :include-footer="true" :dead-area-color="'w3-theme'" :scroll-horizontal="true" id="grid">
                    <template slot="thead">
                        <tr class="w3-border">
                            <th style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                No
                            </th>
                            <th v-for="fl in $store.getters['modLimaceria/filterByShow']" :key="fl.key" :style="'min-width:' + fl.width"  class="w3-light-grey w3-border-0" >{{fl.name}}</th>
                        </tr>
                    </template>
                    <template slot="tbody">
                        <tr :id="'grid-' + index" @mouseover="dtl['pilih']=true" @mouseleave="dtl['pilih']=false" v-for="(dtl, index) in $store.getters['modLimaceria/recs']" style="cursor: pointer;" :class="(dtl.pilih==true?'bayangan w3-theme-l4':(index == $store.getters['modLimaceria/selectedRow'] ?'bayangan w3-theme-l4':'w3-opacity-min'))" :key="dtl.id" v-on:dblclick="showEdit(dtl, index);" class=" w3-hover-border-grey w3-border-bottom">
                            <!-- <td>
                                <img :src="getPath(dtl)" alt="Gambar">
                            </td> -->
                            <td style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                <i :class="index == $store.getters['modLimaceria/selectedRow'] ?'fa fa-caret-right':''"></i>{{index+((pages.halaman-1)*pages.batas)+1}}
                            </td>
                            
							<td v-on:click="$store.dispatch('modLimaceria/setSelectedRow',index)" :style="'min-width:' + findObj(filters.fields,'key','limaceria_name')['width']"  class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.limaceria_name,'limaceria_name')"></span>
                            </td>
							<td v-on:click="$store.dispatch('modLimaceria/setSelectedRow',index)" :style="'min-width:' + findObj(filters.fields,'key','limaceria_keterangan')['width']"  class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.limaceria_keterangan,'limaceria_keterangan')"></span>
                            </td>

							<td v-on:click="$store.dispatch('modLimaceria/setSelectedRow',index)" :style="'min-width:' + findObj(filters.fields,'key','user_entry')['width']"  class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.user_entry,'user_entry')"></span>
                            </td>
                            <td v-on:click="$store.dispatch('modLimaceria/setSelectedRow',index)" :style="'min-width:' + findObj(filters.fields,'key','date_entry')['width']"  class="w3-border-0 w3-border-left">
                                {{dtl.date_entry | moment("DD-MM-yyyy HH:mm:ss")}}
                            </td>
                            <td v-on:click="$store.dispatch('modLimaceria/setSelectedRow',index)" :style="'min-width:' + findObj(filters.fields,'key','user_edit')['width']"  class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.user_edit,'user_edit')"></span>
                            </td>
                            <td v-on:click="$store.dispatch('modLimaceria/setSelectedRow',index)" :style="'min-width:' + findObj(filters.fields,'key','date_edit')['width']"  class="w3-border-0 w3-border-left">
                                {{dtl.date_edit | moment("DD-MM-yyyy HH:mm:ss")}}
                            </td>

                            <td style="padding: 3px 0px 0px 10px;" :style="'min-width:' + findObj(filters.fields,'key','limaceria_status')['width']" class=" w3-hide-small w3-hide-medium w3-center w3-border-left w3-border-right" >
                                <div v-on:click="$store.dispatch('modLimaceria/setSelectedRow',index)" style="position: relative;" :style="'min-width:' + findObj(filters.fields,'key','limaceria_status')['width']">
                                    <span v-html="setStatus(dtl.limaceria_status)"></span>
                                    <div v-show="dtl['pilih']==true && dtl.limaceria_status=='T'" style="position: absolute; top: -5px; left: -5px; text-align:center;" class="w3-opacity-off">
                                        <button title="Show File" style="padding: 5px 7px 5px 7px; margin-right:1px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="showFile(dtl, index)"><i class="fa fa-image w3-large"></i></button>
                                        <button title="Edit" style="padding: 5px 7px 5px 7px; margin-right:1px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="showEdit(dtl, index)"><i class="fa fa-pencil w3-large"></i></button>
                                        <button title="Delete" style="padding: 5px 7px 5px 7px; margin-right:1px;" class="w3-btn w3-circle w3-tiny w3-red" v-on:click="showDelete(dtl, index)"><i class="fa fa-trash-o w3-large"></i></button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template slot="tfoot">
                        <Paging class="w3-center"
                            :click-handler="loadPage"
                            :prev-text="'Prev'"
                            :next-text="'Next'"
                            :container-class="'w3-bar'"
                            :page-count="pages.ttlhalaman"
                            :page-range="10"
                            :margin-pages="2"
                            :value="pages.halaman">
                        </Paging>
                    </template>
                </vue-scrolling-table>
            </div>
        </div>
        <!-- Modal -->
        <div id="id01" class="w3-modal" >
            <vue-draggable-resizable :y="50" :drag-handle="'.drag'" style="width:100%;">
                <div class="w3-modal-content w3-animate-zoom w3-card-4" style="max-width:600px;">   
                    <header class="w3-container w3-theme-d3 drag" style="cursor:move;"> 
                        <span onclick="document.getElementById('id01').style.display='none'" 
                        class="w3-button w3-display-topright">&times;</span>
                        <h6><i class="fa fa-windows"></i> Add / Edit data</h6>
                    </header>
                    <div class="w3-container w3-padding w3-theme-l4">
                        <form method="post" id="frmLimaceria" v-on:submit.prevent="saveData" action="#" autocomplete="off">
							<div class="w3-row" style="margin-top:5px;">
                                <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                    <label for="limaceria_name">{{findObj(filters.fields,"key","limaceria_name")["name"]}} :</label>
                                </div>
                                <div class="w3-col l10 m9 s12">
                                    <input ref="limaceria_name" required type="text" class="w3-input w3-small w3-border w3-border w3-round" id="limaceria_name" :placeholder="findObj(filters.fields,'key','limaceria_name')['name']" v-model="$store.getters['modLimaceria/rec'].limaceria_name">
                                </div>
                            </div>
							<div class="w3-row" style="margin-top:5px;">
                                <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                    <label for="limaceria_keterangan">{{findObj(filters.fields,"key","limaceria_keterangan")["name"]}} :</label>
                                </div>
                                <div class="w3-col l10 m9 s12">
                                    <textarea v-model="$store.getters['modLimaceria/rec'].limaceria_keterangan" class="w3-input w3-border w3-small w3-round-large" id="" cols="30" rows="3"></textarea>
                                </div>
                            </div>
                            <div class="w3-row" style="margin-top:5px;">
                                <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                    <label for="limaceria_file">{{findObj(filters.fields,'key','limaceria_file')['name']}} :</label>
                                </div>
                                <div class="w3-col l10 m9 s12 w3-small" style="position:relative">
                                    <input disabled="true" :title="findObj(filters.fields,'key','limaceria_file')['name']" type="text" class="w3-input w3-small w3-border w3-round-large" ref="galery_foto" id="galery_foto" :placeholder="findObj(filters.fields,'key','limaceria_file')['name']" v-model="$store.getters['modLimaceria/rec'].limaceria_file">
                                    <button  v-on:click="UploadFile()" title="Upload File" style="padding: 5px 7px 5px 7px;position: absolute;top: 3px;right: 3px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" type="button">
                                        <i class="fa fa-upload w3-large"></i>
                                    </button>
                                </div>
                            </div>
							<div class="w3-row" style="margin-top:5px;">
                                <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                    <label for="limaceria_status">&nbsp;</label>
                                </div>
                                <div class="w3-col l9 m9 s12">
                                    <label class="container w3-small">{{findObj(filters.fields,"key","limaceria_name")["name"]}}
                                        <input id="limaceria_status" v-model="$store.getters['modLimaceria/rec'].limaceria_status" type="checkbox" true-value="T" false-value="F">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <footer class="w3-container w3-theme-l1">
                        <label class="container w3-small w3-left" style="margin-top: 15px;">Auto add
                            <input true-value="T" false-value="F" v-model="pages.autoadd" type="checkbox" checked="checked">
                            <span class="checkmark w3-round"></span>
                        </label>
                        <h6 class="w3-right">
                            <button form="frmLimaceria" type="submit" class="w3-btn w3-small w3-theme-d5 w3-round" style="margin-right:5px;"><i class="fa fa-save"></i> Save</button>
                            <button onclick="document.getElementById('id01').style.display='none'" class="w3-btn w3-small w3-red w3-round"><i class="fa fa-close"></i> Close</button>
                        </h6>
                    </footer>
                </div>
            </vue-draggable-resizable>
        </div>
        <div id="idFile" class="w3-modal" >
            <vue-draggable-resizable :y="-10" :drag-handle="'.drag'" style="width:100%;">
                <div class="w3-modal-content w3-animate-zoom w3-card-4" style="width:90%;">   
                    <header class="w3-container w3-theme-d3 drag" style="cursor:move;"> 
                        <span onclick="document.getElementById('idFile').style.display='none'" 
                        class="w3-button w3-display-topright">&times;</span>
                        <h6><i class="fa fa-windows"></i> Preview</h6>
                    </header>
                    <div class="w3-container w3-padding w3-theme-l4">
                        <div class="w3-responsive w3-grey w3-padding" v-bind:style="'height:'+  (screenHeight-250) +'px;'" align="center">
                            <DeLoading :show="halaman.loading"></DeLoading>   
                            <img v-if="!halaman.loading" :src="halaman.url + halaman.nomor + '.png'" alt="" class="w3-image" style="height: 800px;">
                        </div>
                        <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l6 m6 s6">
                                <button type="button" class="w3-right" style="margin-right:30px;" @click="()=>{
                                    if (halaman.nomor>1){
                                        halaman.nomor--;
                                    }
                                }"> Prev </button>
                            </div>
                            <div class="w3-col l6 m6 s6" style="position: relative;">
                                <input v-model="halaman.nomor" type="number" style="position: absolute; left: -25px; width: 50px;">
                                <button type="button" class="w3-left" style="margin-left:30px;"  @click="()=>{
                                    if (halaman.nomor<halaman.totalpage){
                                        halaman.nomor++;
                                    }
                                }"> Next </button>
                            </div>
                        </div>
                    </div>
                </div>
            </vue-draggable-resizable>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
export default {
    head: {
        title: {
            inner: "Limaceria"
        }
    },
    name: "Limaceria",
    components: {},
    data() {
        return {
            "grid" : false,
            "screenHeight" : 0,
            "filters" : {},
            "pages": {},
            "json_fields" : {"Kode" : "limaceria_code","Nama" : "limaceria_title"},
            "halaman": {"totalpage":1, "nomor": 1, "loading": true, "url":"https://demo.do-event.com/files/no-image.jpg"}
        };
    },
    created(){
        this.filters = this.$store.getters["modLimaceria/filters"];
        this.pages = this.$store.getters["modLimaceria/pages"];
    },
    methods : {
        setTextFind(txt,field){
            let fieldvalue = "";
            let ketemu = false;
            const self = this;
            if(self.filters.find!==undefined )
                if ( self.filters.find.length >0 ) {
                    self.filters.find.map(function(value){
                        if(value.field ===field){
                            ketemu = true;
                        }
                        if(fieldvalue!=="null")
                            fieldvalue += ((fieldvalue!=="")?";":"")+ value.fieldValue ;
                        else
                            fieldvalue += ((fieldvalue!=="null")?";":"")+ value.fieldValue ;
                    });
                }
            if(ketemu){
                return self.sethighlight(txt,fieldvalue);
            }
            return txt;
        },
        loadPage (pageNum) {
            this.pages.halaman = pageNum;
            this.$store.dispatch("modLimaceria/setPages",this.pages);
            this.$store.dispatch("modLimaceria/setFilters",this.filters);
            this.loadData();
        },
        setFilter (filter, operator) {
            this.filters.find = filter;
            this.filters.operator = operator;
            this.$store.dispatch("modLimaceria/setFilters",this.filters);
            this.loadData();
        },
        loadData () {
            this.$store.dispatch("modLimaceria/setSelectedRow",-1);
            this.$store.dispatch("modLimaceria/loadData");
        },
        async showAdd(){
            const self = this;
            self.stsAdd = true;
            this.$store.dispatch("modLimaceria/setRec", { 
                "event": "add",
                "pilih": false,
                "id" : self.getCode(),
                "id_old" : "",
                "limaceria_icon" : "",
                "limaceria_name" : "",
                "limaceria_keterangan" : "",
                "limaceria_file" : "",
                "limaceria_maxpage" : 1,
                "limaceria_status" : "T",
                "user_entry": localStorage.username,
                "user_edit": localStorage.username,
                "date_entry": moment(String(new Date())).format("YYYY-MM-DD"),
                "date_edit": moment(String(new Date())).format("YYYY-MM-DD")
            });
            // console.log(self.rec);
            if(document.getElementById("id01").style.display!="block")
                document.getElementById("id01").style.display="block";
            setTimeout(function(){
                document.getElementById("limaceria_name").focus();
            },500);
        },
        showEdit(dt, idx){
            this.$store.dispatch("modLimaceria/setSelectedRow",idx);
            dt["event"]= "edit";
            this.$store.dispatch("modLimaceria/setRec",dt);
            document.getElementById("id01").style.display="block";
        },
        showDelete(dt){
            const self = this;
            // eslint-disable-next-line no-undef
            swal({
                title: "Yakin Ingin Menghapus Data ..!",
                text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#566D1D",
                confirmButtonText: "Ya, Hapus Data",
                cancelButtonText: "Tidak, Batal Hapus",
                closeOnConfirm: true,
                closeOnCancel: true
            },function(isConfirm){
                if (isConfirm) {
                    let vparams = {"id" : dt.id};
                    self.apiPost("/brantas/limaceria/delete" ,vparams,function(response){
                        if (response.success === true) {
                            self.$toast.success("Hapus sukses", "Delete");
                            self.$store.dispatch("modLimaceria/deleteRecs", self.$store.getters["modLimaceria/selectedRow"]);
                        } else {
                            self.$toast.error("Gagal Hapus " + response.msg, "Delete");
                        }
                    });                    
                }
            });
        },
        showPrint() {
            this.$toast.info("Under Production");
        },
        setStatus(dt) {
            if (dt === "T") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Active</div>";
            }
            return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
        },
        saveData(event){
            const self = this;
            self.apiPost("/brantas/limaceria/" + self.$store.getters["modLimaceria/rec"].event, self.$store.getters["modLimaceria/rec"], function (response) {
                if (response.success) {
                    if (self.$store.getters["modLimaceria/rec"].id_old == "") {
                        self.$store.getters["modLimaceria/rec"].id = response.key;
                        self.$store.dispatch("modLimaceria/addRecs", self.$store.getters["modLimaceria/rec"]);
                    } else {
                        self.$store.dispatch("modLimaceria/editRecs", {"rec":self.$store.getters["modLimaceria/rec"], "index": self.$store.getters["modLimaceria/selectedRow"]});
                    }
                    document.getElementById("id01").style.display = "none";
                    self.$toast.success("Sukses Simpan data", "save");
                    if (self.pages.autoadd === "T") {
                        self.showAdd();
                    }
                } else {
                    // swal({title: "Save",text: "Gagal simpan data",type: "error",html: true});
                    self.$toast.error("Simpan gagal " + response.msg, "Save");
                }
            });
            event.preventDefault();
            return false;

        },
        selectFile(contentType, multiple) {
            return new Promise(resolve => {
                let input = document.createElement("input");
                input.type = "file";
                input.multiple = multiple;
                input.accept = contentType;
                // eslint-disable-next-line no-unused-vars
                input.onchange = _ => {
                    let files = Array.from(input.files);
                    if (multiple) {
                        resolve(files);
                    } else {
                        resolve(files.first);
                    }
                };
                input.click();
            });
        },
        async UploadFile(){
            const self = this;
            let files = await this.selectFile("application/pdf", true);
            self.showLoading("Upload ..");
            let vparams =[{key : "folder","val":"limaceria"}];
            self.apiUpload("/tproject/admin/uploadfile",files[0],vparams, function(res){
                self.$store.getters["modLimaceria/rec"].limaceria_file = res.nama_file;
                self.$store.getters["modLimaceria/rec"].limaceria_maxpage = res.ttlpage;
                setTimeout(function(){
                    console.log(self.$store.getters["modLimaceria/rec"]);
                    self.hideLoading();
                },2000);
            }, function(err){
                // eslint-disable-next-line no-console
                console.log(err);
                // self.hideLoading();
            }, function(bil){
                // eslint-disable-next-line no-console
                console.log(bil);
                // self.hideLoading();
            });
        },
        showFile(dtl, index){
            this.halaman.loading = true;
            this.halaman.totalpage = dtl.limaceria_maxpage;
            this.halaman.nomor = 1;
            this.halaman.url =  this.apiUrl + "/gambar/" + dtl.limaceria_file + "-pecah/image."
            this.halaman.loading = false;
            document.getElementById('idFile').style.display = "block";
        },
        getImage(){

            // return "https://demo.do-event.com/files/no-image.jpg"
        }
    },
    computed :{
        filterByShow(){
            return this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        }
    },
    filters: {
        
    },
    watch: {
        "$route": function(){
        }
    },
    mounted(){
        let self = this;
        self.pages = {halaman: 1,batas: self.perPage,ttlhalaman: 0,ttlrec: 0,autoadd: "F"};
        self.screenWidth = screen.width;
        self.screenHeight = window.innerHeight;
        // self.eventGrid(self, "grid", "selectedRow", "recs");
		this.eventGridStore(this, "grid", "modJenisGaji/selectedRow",function(nilai){
            self.$store.dispatch("modJenisGaji/setSelectedRow",nilai);
        });
        self.getGridColumnmAutoWidth("grid",self.filters.fields, false);
        self.loadData();
    }
};
</script>
<style>
.upload-btn-wrapper input[type=file] {
    font-size: 112px;
    position: absolute;
    left: 159px;
    top: 58px;
    width: 150px;
    height: 180px;
    opacity: 0;
    /*font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;*/
}
</style>